<template>
  <v-carousel
    cycle
    class="carousel"
    :height="height"
    interval="12000"
    hide-delimiters
    :show-arrows-on-hover="$vuetify.breakpoint.smAndUp ? true: false"
    :show-arrows="$vuetify.breakpoint.smAndUp ? true: false"
  >
    <v-carousel-item
        transition="slide-fade"
        reverse-transition="slide-fade"
        v-for="(image, i) in images"
        :key="i"
    >
      <v-sheet
        height="100%"
        color="white"
      >
        <!-- <v-img :src="image.src" class="bg-image"> -->
          <!-- <v-row
            class="fill-height"
            align="center"
            justify="center"
          > -->
          <v-img contain max-height="500" :src="image.src" class="main-image"></v-img>
          <!-- </v-row> -->
        <!-- </v-img> -->
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    data: () => ({
      images: [
        // {src: require('../assets/images/Carousel/five.jpg')},
        {src: require('../assets/images/Carousel/front.jpg')},
        {src: require('../assets/images/Carousel/back.jpg')},
        {src: require('../assets/images/Carousel/side.jpg')},
      ],
    }),
    computed: {
      height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 300
          case 'sm': return 450
          case 'md': return 500
          case 'lg': return 500
          case 'xl': return 500
          default: return 500
        }
      },
    },
  }
</script>
<style scoped>
.carousel {
    width: 100%;
    height: 600px;
}
.slide-fade-enter-active {
  transition: all 2s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.main-image {
  filter: unset;
  -webkit-filter: unset;
}
.bg-image {

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 200%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>